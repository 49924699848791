<template>
  <div class="page">
    <div class="page__header" style="margin-bottom: 25px">
      <h1 style="margin-top: 10px">Collection</h1>
      <div class="page__header-search">
        <BaseSelect
          class="form-control--grey form-control--xs"
          placeholder="Search by"
          :selected="searchType"
          :items="searchTypes"
          @input="searchType = $event.id"
        ></BaseSelect>
        <BaseInput
          class="form-control--grey form-control--xs"
          style="max-width: 65rem"
          placeholder="Search"
          :icon="true"
          :timeout="true"
          :element="tableData.search"
          @input="searchItems"
        >
          <template #icon>
            <IconSearch color="#000" height="16" width="16"></IconSearch>
          </template>
        </BaseInput>
      </div>
    </div>
    <div class="page__header">
      <HorisontalTabsList :tabs="tabs"></HorisontalTabsList>
      <div class="page__header_right">
        <FilterBlock
          :items="filters"
          @open="openFilters = $event"
          @selectFilter="selectFilter"
          @apply="refreshSelectedFilters"
          @clear="refreshSelectedFilters(true)"
        ></FilterBlock>
        <ViewToggle @input="viewType = $event"></ViewToggle>
        <IconButton
          class="button--admin button--auto button--outline-grey button--ml-15"
          text="add artist"
          @click="modal = true"
        >
          <IconPlusBold></IconPlusBold>
        </IconButton>
      </div>
    </div>
    <div class="page__filters">
      <template v-for="filter in selectedFilters"
        ><Tag
          class="tag--capitalize"
          v-for="i in filter.items"
          :key="i.title"
          :value="i.title"
          :close="true"
          :disabled="openFilters"
          @deleteTag="selectFilter({ key: filter.key, filter: i, apply: true })"
        ></Tag
      ></template>
    </div>
    <template v-if="viewType === 'list'">
      <Table
        :loading="loading"
        :items="items"
        :headerItems="headerItems"
        :tableData="tableData"
        :hasMorePages="hasMorePages"
        @sortTable="sortTable"
        @clickAction="clickAction"
        @loadMore="loadMore"
      ></Table>
    </template>
    <CardView
      v-else
      :items="items"
      @clickAction="clickAction"
      :hasMorePages="hasMorePages"
      @loadMore="loadMore"
    ></CardView>
    <Artist
      v-if="modal"
      :modal="modal"
      :item="artist"
      @hideModal="modal = false"
      @createArtist="createArtist"
      @updateArtist="updateArtist"
      @removeArtist="confirmRemoveArtist"
      @updateTag="apiGetFiltersBlock(filterBlock)"
    ></Artist>
  </div>
</template>
<script>
import BaseInput from "@/components/inputs/BaseInput.vue";
import IconSearch from "@/components/icons/IconSearch.vue";
import HorisontalTabsList from "@/components/HorisontalTabsList.vue";
import FilterBlock from "@/components/Filter.vue";
import ViewToggle from "@/components/ViewToggle.vue";
import Tag from "@/components/Tag.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import IconPlusBold from "@/components/icons/IconPlusBold.vue";
import Table from "@/components/Table.vue";
import CardView from "@/components/CardView.vue";
import Artist from "./Artist.vue";
import BaseSelect from "@/components/inputs/BaseSelect.vue";
import { mapMutations, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BaseInput,
    IconSearch,
    HorisontalTabsList,
    FilterBlock,
    IconButton,
    IconPlusBold,
    Table,
    CardView,
    Tag,
    Artist,
    ViewToggle,
    BaseSelect,
  },
  data() {
    return {
      tabs: [
        {
          title: "Artworks",
          path: "/admin/collection/artworks",
          key: "artwork",
        },
        {
          title: "Artists",
          path: "/admin/collection/artists",
          key: "artists",
        },
        {
          title: "Publications",
          path: "/admin/collection/publications",
          key: "publications",
        },
      ],
      viewType: "list",
      modal: false,
      filterBlock: {
        list: "regions,statuses",
        type: "collection",
      },
      openFilters: false,
      tableData: {
        page: 1,
        sort: "last_name",
        order: "asc",
        status: "",
        regions: "",
        search: "",
        search_type: "",
      },
      searchTypes: [
        {
          id: "artist_name",
          title: "Artist Name",
        },
      ],
      searchType: "artist_name",
    };
  },
  async created() {
    await this.apiGetFiltersBlock(this.filterBlock);
    await this.apiGetArtists(this.tableData);
    this.initialItem();
    await this.apiGetRegions();
  },
  computed: {
    ...mapGetters("filters", {
      filters: "getFilters",
      selectedFilters: "getSelectedFilters",
    }),
    ...mapGetters("artists", {
      items: "getItems",
      headerItems: "getItemsHeaders",
      artist: "getItem",
      loading: "getLoading",
      hasMorePages: "getHasMorePages",
    }),
  },
  watch: {
    modal(val) {
      if (!val) {
        this.initialItem();
        this.setError(null);
      }
    },
  },
  methods: {
    ...mapActions("general", ["apiDeleteUploadImage", "apiGetRegions"]),
    ...mapActions("filters", ["apiGetFiltersBlock"]),
    ...mapMutations("filters", ["updateFilters", "updateSelectedFilters", "clear"]),
    ...mapActions("artists", [
      "apiGetArtists",
      "apiGetArtist",
      "apiPostArtist",
      "apiPutArtist",
      "apiDeleteArtist",
    ]),
    ...mapMutations("artists", ["initialItem", "setError"]),
    selectFilter(val) {
      this.updateFilters(val);
      if (val.apply) {
        this.refreshSelectedFilters();
      }
    },
    async sortTable({ sort, order }) {
      let data = { ...this.tableData, page: 1, sort, order };
      let success = await this.apiGetArtists(data);
      if (success) {
        this.tableData = data;
      }
    },
    async loadMore() {
      if (this.hasMorePages) {
        let data = { ...this.tableData, page: this.tableData.page + 1 };
        let success = await this.apiGetArtists(data);
        if (success) {
          this.tableData = data;
        }
      }
    },
    async searchItems(val) {
      if (this.tableData.search != val || this.tableData.search_type != this.searchType) {
        let data = { ...this.tableData, page: 1, search: val, search_type: this.searchType };
        let success = await this.apiGetArtists(data);
        if (success) {
          this.tableData = data;
        }
      }
    },
    async refreshSelectedFilters(clear) {
      if (clear) {
        await this.clear();
      }
      await this.updateSelectedFilters();
      this.filterTable();
    },
    async filterTable() {
      let data = { ...this.tableData, page: 1 };
      this.selectedFilters.forEach(el => {
        let filters = el.items.map(i => {
          return i.id;
        });
        data[el.key] = filters.join(",");
      });
      let success = await this.apiGetArtists(data);
      if (success) {
        this.tableData = data;
      }
    },
    async clickAction({ key, id, item }) {
      switch (key) {
        case "edit": {
          let result = await this.apiGetArtist(id);
          if (result) {
            this.modal = true;
          }
          break;
        }
        case "delete": {
          let result = await this.confirmRemoveArtist(item);
          if (result) {
            this.modal = true;
          }
          break;
        }
        case "view": {
          if (item.status !== "inactive") this.$router.push({ path: `/artists/${item.slug}` });
          break;
        }
      }
    },

    async createArtist({ data, imageForRemove }) {
      let result = await this.apiPostArtist(data);
      if (result) {
        let data = { ...this.tableData, page: 1 };
        let success = await this.apiGetArtists(data);
        if (success) {
          this.tableData = data;
        }
        this.modal = false;
        /*if (imageForRemove.length) {
          this.apiDeleteUploadImage(imageForRemove);
        }*/
      }
    },
    async updateArtist({ data, imageForRemove }) {
      let result = await this.apiPutArtist(data);
      if (result) {
        let data = { ...this.tableData, page: 1 };
        let success = await this.apiGetArtists(data);
        if (success) {
          this.tableData = data;
        }
        this.modal = false;
        /*if (imageForRemove.length) {
          this.apiDeleteUploadImage(imageForRemove);
        }*/
      }
    },
    confirmRemoveArtist(item) {
      this.$swal({
        title: `Delete ${item.first_name}`,
        html: "<h5>Are you sure? <br/> This cannot be undone.",
        showCancelButton: true,
        showConfirmButton: false,
        showDenyButton: true,
        denyButtonText: "Delete",
        cancelButtonText: "Cancel",
      }).then(result => {
        if (result.value === false) {
          this.removeArtist(item.id);
        }
      });
    },
    async removeArtist(id) {
      let result = await this.apiDeleteArtist(id);
      if (result) {
        let data = { ...this.tableData, page: 1 };
        let success = await this.apiGetArtists(data);
        if (success) {
          this.tableData = data;
        }
        this.modal = false;
      }
    },
  },
};
</script>

